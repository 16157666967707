import React, {useState} from 'react';
import {useFormik} from 'formik';
import {useNavigate} from 'react-router-dom';
import {getAuth, createUserWithEmailAndPassword, updateProfile} from 'firebase/auth';
import {getFirestore, setDoc, doc} from 'firebase/firestore';
import {getApp} from 'firebase/app';
import {
    Box,
    Container,
    Button,
    TextField,
    Typography,
    InputAdornment,
    IconButton,
    ThemeProvider,
    createTheme,
} from '@mui/material';
import {Visibility, VisibilityOff} from '@mui/icons-material';
import Navbar from '../components/Navbar';

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    padding: '12px 30px',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        boxShadow: '0 6px 12px rgba(103, 58, 183, 0.3)',
                    },
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '& .MuiOutlinedInput-root': {
                        transition: 'all 0.3s ease',
                        borderRadius: 10,
                        '& fieldset': {
                            color: '#8050d3',
                        },
                        '&:hover fieldset': {
                            borderColor: 'white',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#673ab7',
                        },
                    },
                    '& .MuiInputLabel-root': {
                        color: '#d1c4e9',
                    },
                    '& .MuiInputBase-input': {
                        color: '#ffffff',
                    },
                },
            },
        },
        MuiIconButton: {
            styleOverrides: {
                root: {
                    color: '#d1c4e9',
                    transition: 'all 0.3s ease',
                    '&:hover': {
                        backgroundColor: 'rgba(103, 58, 183, 0.2)',
                        transform: 'scale(1.1)',
                    },
                },
            },
        },
    },
});

interface SignupProps {
    onClick: (name: string) => void;
}

const Signup: React.FC<SignupProps> = ({onClick}) => {
    const [errorMessage, setErrorMessage] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const navigate = useNavigate();

    const handleTogglePassword = () => {
        setShowPassword(!showPassword);
    };

    const fbSignUp = async (values: {
        email: string;
        password: string;
        name: string;
        username: string;
    }) => {
        const {email, password, name, username} = values;
        const app = getApp();
        const auth = getAuth();
        const db = getFirestore(app);

        try {
            const userCredential = await createUserWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
            await updateProfile(user, {displayName: name});

            await setDoc(doc(db, 'users', user.uid), {
                name,
                username,
                email,
            });

            navigate('/');
        } catch (error) {
            console.error('Error signing up:', error);
            setErrorMessage('An account with this email already exists!');
        }
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            username: '',
            password: '',
        },
        onSubmit: (values) => {
            if (values.password.length < 8) {
                setErrorMessage('Your password must be at least 8 characters long');
            } else {
                setErrorMessage('');
                fbSignUp(values);
            }
        },
    });

    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    background: `
    radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
    radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
`,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}
            >
                <Navbar/>
                <Container
                    sx={{
                        display: 'flex',
                        flexGrow: 1,
                        alignItems: 'center',
                        justifyContent: 'center',
                    }}
                >
                    <Box
                        sx={{
                            backgroundColor: '#2b2d30',
                            maxWidth: '400px',
                            width: '100%',
                            padding: 5,
                            borderRadius: 4,
                            boxShadow: '0 4px 30px rgba(0, 0, 0, 0.7)',
                            border: '1px solid gray',
                        }}
                    >
                        <form onSubmit={formik.handleSubmit}>
                            <Typography
                                variant="h4"
                                align="center"
                                gutterBottom
                                sx={{
                                    cursor: 'default',
                                    fontWeight: 'bold',
                                    padding: '20px 0', // Reduced padding
                                    color: '#8050d3',
                                    textShadow: '2px 2px 4px rgba(0,0,0,0.3)',
                                    transition: 'all 0.3s ease-in-out',
                                    userSelect: 'none',
                                    WebkitUserSelect: 'none',
                                    MozUserSelect: 'none',
                                    msUserSelect: 'none',
                                    '&:hover': {
                                        color: '#e1bee7',
                                    },
                                }}
                            >
                                Sign Up
                            </Typography>
                            <TextField
                                fullWidth
                                name="name"
                                label="Name"
                                value={formik.values.name}
                                onChange={formik.handleChange}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                name="email"
                                label="Email"
                                value={formik.values.email}
                                onChange={formik.handleChange}
                                margin="normal"
                                variant="outlined"
                                type="email"
                            />
                            <TextField
                                fullWidth
                                name="username"
                                label="Username"
                                value={formik.values.username}
                                onChange={formik.handleChange}
                                margin="normal"
                                variant="outlined"
                            />
                            <TextField
                                fullWidth
                                name="password"
                                label="Password"
                                value={formik.values.password}
                                onChange={formik.handleChange}
                                margin="normal"
                                variant="outlined"
                                type={showPassword ? 'text' : 'password'}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton
                                                onClick={handleTogglePassword}
                                                edge="end"
                                                sx={{color: 'rgba(255, 255, 255, 0.7)'}}
                                            >
                                                {showPassword ? <VisibilityOff/> : <Visibility/>}
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                            />
                            {errorMessage && (
                                <Typography color="error" variant="body2" sx={{
                                    userSelect: 'none',
                                    WebkitUserSelect: 'none',
                                    MozUserSelect: 'none',
                                    msUserSelect: 'none',
                                    mt: 2
                                }}>
                                    {errorMessage}
                                </Typography>
                            )}
                            <Button
                                type="submit"
                                variant="contained"
                                color="primary"
                                fullWidth
                                sx={{mt: 3}}
                            >
                                Sign Up
                            </Button>
                            <Typography className="text-center mt-2" sx={{
                                userSelect: 'none',
                                WebkitUserSelect: 'none',
                                MozUserSelect: 'none',
                                msUserSelect: 'none',
                                color: '#d1c4e9'
                            }}>
                                Already have an account?{' '}
                                <Box
                                    component="span"
                                    sx={{
                                        display: 'inline-block',
                                        transition: 'transform 0.3s ease-in-out',
                                        '&:hover': {
                                            transform: 'scale(1.01)',
                                        },
                                    }}
                                >
                                    <a href="/login" style={{color: '#ff4081'}}>
                                        Login
                                    </a>
                                </Box>
                            </Typography>
                        </form>
                    </Box>
                </Container>
            </Box>
        </ThemeProvider>
    );
};

export default Signup;