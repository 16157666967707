import React from "react";
import {
    Container,
    Typography,
    Box,
    ThemeProvider,
    createTheme,
    Link,
} from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Cog } from 'lucide-react';

const theme = createTheme({
    palette: {
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#000000',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiLink: {
            styleOverrides: {
                root: {
                    color: '#5b1e6c',
                    textDecoration: 'none',
                    '&:hover': {
                        textDecoration: 'underline',
                    },
                },
            },
        },
    },
});

function Content() {
    return (
        <ThemeProvider theme={theme}>
            <Box
                sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    background: `
                        radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
                        radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
                    `,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}
            >
                <Navbar/>
                <Container
                    sx={{
                        flexGrow: 1,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                        justifyContent: 'center',
                        py: 4,
                    }}
                >
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            maxWidth: '600px',
                            textAlign: 'center',
                        }}
                    >
                        <Cog
                            size={150}
                            color="#673ab7"
                            style={{
                                marginBottom: '2rem',
                                animation: 'spin 30s linear infinite',
                            }}
                        />
                        <Typography
                            variant="h3"
                            sx={{
                                mb: 2,
                                fontWeight: 'bold',
                                color:'#3f2669'
                            }}
                        >
                            Under Construction
                        </Typography>
                    </Box>
                </Container>
                <Footer/>
            </Box>
            <style>
                {`
                    @keyframes spin {
                        from { transform: rotate(0deg); }
                        to { transform: rotate(360deg); }
                    }
                `}
            </style>
        </ThemeProvider>
    );
}

export default Content;