import React, {useEffect, useRef, useState} from "react";
import {
    Container,
    Typography,
    Box,
    ThemeProvider,
    createTheme,
    Card,
    CardContent,
    Button,
    Grid,
    List,
    ListItem,
    ListItemText,
    Divider,
    Paper, IconButton,
} from "@mui/material";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import {getAuth, onAuthStateChanged} from "firebase/auth";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import {useNavigate} from "react-router-dom";
import {motion, AnimatePresence} from "framer-motion";
import DeleteIcon from '@mui/icons-material/Delete';
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';

const theme = createTheme({
    palette: {
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#000000',
            secondary: '#ffffff',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',

    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    cursor: 'default',
                    userSelect: 'none',
                    WebkitUserSelect: 'none',
                    MozUserSelect: 'none',
                    msUserSelect: 'none',
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    padding: '8px 16px',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 4px 8px rgba(103, 58, 183, 0.3)',
                    },
                    '&:active': {
                        boxShadow: '0 4px 8px rgba(103, 58, 183, 0.3)',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    borderRadius: 10,
                    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
                    backdropFilter: 'blur(20px)',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 6px 12px rgba(103, 58, 183, 0.6)',
                    },
                },
            },
        },
    },
});

interface Product {
    id: number;
    name: string;
    price: number;
    theme: {
        background: string;
        color: string;
        buttonColor: string;
    };
}

const products: Product[] = [
    {
        id: 1,
        name: "CodeAPeel C",
        price: 29.99,
        theme: {
            background: 'linear-gradient(90deg, #9c27b0 0%, #ba68c8 100%)',
            color: '#ffffff',
            buttonColor: '#7b1fa2'
        }
    },
    {
        id: 2,
        name: "CodeAPeel H",
        price: 29.99,
        theme: {
            background: 'linear-gradient(90deg, #4caf50 0%, #81c784 100%)',
            color: '#ffffff',
            buttonColor: '#388e3c'
        }
    },
    {
        id: 3,
        name: "CodeAPeel M",
        price: 29.99,
        theme: {
            background: 'linear-gradient(90deg, #ff9800 0%, #ffb74d 100%)',
            color: '#ffffff',
            buttonColor: '#f57c00'
        }
    },
];

function Order() {
    const [basket, setBasket] = useState<Product[]>([]);
    const [openNavExternal, setOpenNavExternal] = useState(false);
    const resultContainer = useRef<HTMLDivElement>(null);
    const [userName, setUserName] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const navigate = useNavigate();

    const auth = getAuth();
    const authJwt = useAuthUser();


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setIsLoggedIn(true);
                setUserName(user.displayName || user.email || "User");
            } else {
                setIsLoggedIn(false);
                setUserName("");

            }
            setIsLoading(false)
        });

        return () => unsubscribe();
    }, []);


    const addToBasket = (product: Product) => {
        if (!basket.some(item => item.id === product.id)) {
            setBasket([...basket, product]);
        }
    };

    const removeFromBasket = (productId: number) => {
        setBasket(basket.filter(item => item.id !== productId));
    };

    const isInBasket = (productId: number) => {
        return basket.some(item => item.id === productId);
    };

    const totalPrice = basket.reduce((sum, item) => sum + item.price, 0).toFixed(2);

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                background: `radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%), radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)`,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed'
            }}>
                <Navbar/>
                <Container sx={{flexGrow: 1, display: 'flex', flexDirection: 'column', py: 4}}>
                    <Typography variant="h4" sx={{mb: 4, color: 'text.primary', fontWeight: 'bold'}}>
                        Products
                    </Typography>
                    <Grid container spacing={4}>
                        <Grid item xs={12} md={8}>
                            <Grid container spacing={3}>
                                {products.map((product) => (
                                    <Grid item xs={12} sm={6} md={4} key={product.id}>
                                        <Card sx={{background: product.theme.background}}>
                                            <CardContent>
                                                <Typography variant="h6" sx={{color: product.theme.color, mb: 2}}>
                                                    {product.name}
                                                </Typography>
                                                <Typography variant="body1"
                                                            sx={{color: product.theme.color, mb: 2}}>
                                                    ${product.price.toFixed(2)}
                                                </Typography>
                                                {isLoggedIn ? (
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: product.theme.buttonColor,
                                                            color: product.theme.color,
                                                            '&:hover': {
                                                                backgroundColor: product.theme.buttonColor,
                                                                opacity: 0.9,
                                                            },
                                                        }}
                                                        onClick={() => addToBasket(product)}
                                                        disabled={isInBasket(product.id)}
                                                    >
                                                        {isInBasket(product.id) ? 'In Basket' : 'Add to Basket'}
                                                    </Button>
                                                ) : (
                                                    <Button
                                                        variant="contained"
                                                        sx={{
                                                            backgroundColor: product.theme.buttonColor,
                                                            color: product.theme.color,
                                                            '&:hover': {
                                                                backgroundColor: product.theme.buttonColor,
                                                                opacity: 0.9,
                                                            },
                                                        }}
                                                        onClick={() => navigate('/login')}
                                                    >
                                                        Log In to Purchase
                                                    </Button>
                                                )}
                                            </CardContent>
                                        </Card>
                                    </Grid>
                                ))}
                            </Grid>
                        </Grid>
                        <Grid item xs={12} md={4}>
                            <Paper elevation={3} sx={{borderRadius: '15px', overflow: 'hidden'}}>
                                <Box sx={{background: 'linear-gradient(45deg, #673ab7 30%, #3f51b5 90%)', p: 2}}>
                                    <Typography variant="h5"
                                                sx={{
                                                    color: 'white',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}>
                                        <ShoppingBasketIcon sx={{
                                            mr: 1
                                        }}/> Your Basket
                                    </Typography>
                                </Box>
                                <List sx={{maxHeight: '400px', overflowY: 'auto'}}>
                                    <AnimatePresence>
                                        {basket.map((item, index) => (
                                            <motion.div
                                                key={item.id}
                                                initial={{opacity: 0}}
                                                animate={{opacity: 1}}
                                                exit={{opacity: 0}}
                                                transition={{duration: 0.3}}
                                            >
                                                <ListItem>
                                                    <ListItemText
                                                        primary={<Typography
                                                            color="text.secondary">{item.name}</Typography>}
                                                        secondary={<Typography
                                                            color="text.secondary">${item.price.toFixed(2)}</Typography>}
                                                    />
                                                    <IconButton onClick={() => removeFromBasket(item.id)}
                                                                color="secondary">
                                                        <DeleteIcon/>
                                                    </IconButton>
                                                </ListItem>
                                                {index < basket.length - 1 && <Divider/>}
                                            </motion.div>
                                        ))}
                                    </AnimatePresence>
                                </List>
                                <Box sx={{p: 2}}>
                                    <Typography variant="h6" sx={{mb: 2, color: 'text.secondary'}}>
                                        Total: ${totalPrice}
                                    </Typography>
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        fullWidth
                                        sx={{backgroundColor: 'green'}}
                                        disabled={basket.length === 0}
                                    >
                                        Checkout (Coming Soon)
                                    </Button>
                                </Box>
                            </Paper>
                        </Grid>
                    </Grid>
                </Container>
                <Footer/>
            </Box>
        </ThemeProvider>
    );
}

export default Order;