import React, {useState, useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import ClearIcon from '@mui/icons-material/Clear';
import {
    Box,
    Container,
    Typography,
    TextField,
    Button,
    Card,
    CardContent,
    ThemeProvider,
    createTheme,
    CircularProgress,
    InputAdornment,
    IconButton,
} from '@mui/material';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';
import {
    getFirestore,
    doc,
    getDoc,
    collection,
    addDoc,
    query,
    where,
    getDocs,
    deleteDoc,
    serverTimestamp,
    DocumentData,
    Timestamp
} from "firebase/firestore";
import {getApp} from "firebase/app";
import {SearchIcon} from "lucide-react";
import {getAuth, onAuthStateChanged, signOut} from "firebase/auth";

const theme = createTheme({
    palette: {
        mode: 'dark',
        primary: {
            main: '#673ab7',
        },
        secondary: {
            main: '#ff4081',
        },
        background: {
            default: '#121212',
            paper: '#1e1e1e',
        },
        text: {
            primary: '#ffffff',
            secondary: '#d1c4e9',
        }
    },
    typography: {
        fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    padding: '8px 16px',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 4px 8px rgba(103, 58, 183, 0.3)',
                    },
                    '&.MuiButton-containedError': {
                        background: 'linear-gradient(45deg, #ff1744 30%, #ff4081 90%)',
                        color: '#ffffff',
                        '&:hover': {
                            transition: 'all 0.3s ease-in-out',

                            background: 'linear-gradient(45deg, #d50000 30%, #ff1744 90%)',
                            boxShadow: '0 4px 8px rgba(255, 23, 68, 0.4)',
                        },
                    },
                    '&.MuiButton-textError': {
                        color: '#ff1744',
                        '&:hover': {
                            transition: 'all 0.3s ease-in-out',

                            background: 'rgba(255, 23, 68, 0.1)',
                            boxShadow: '0 4px 8px rgba(255, 23, 68, 0.2)',
                        },
                    }
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {

                    '& .MuiOutlinedInput-root': {
                        borderRadius: 10,
                        '& fieldset': {
                            borderColor: 'rgba(103, 58, 183, 0.3)',
                        },
                        '&:hover fieldset': {
                            borderColor: 'rgba(103, 58, 183, 0.5)',
                        },
                        '&.Mui-focused fieldset': {
                            borderColor: '#673ab7',
                        },
                    },
                    '& .MuiInputBase-input': {
                        color: 'black',
                    },
                },
            },
        },
        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: '#2b2d30',
                    transition: 'all 0.3s ease-in-out',
                    '&:hover': {
                        boxShadow: '0 8px 16px rgba(103, 58, 183, 0.2)',
                    },
                },
            },
        },
    },
});


interface Comment extends DocumentData {
    id: string;
    authorName: string;
    authorId: string;
    comment: string;
    createDate: Timestamp;
}

function Context() {
    const [postTitle, setPostTitle] = useState("");
    const [postContent, setPostContent] = useState("");
    const [comments, setComments] = useState<Comment[]>([]);
    const [newComment, setNewComment] = useState("");
    const [filter, setFilter] = useState("");
    const [contentFilter, setContentFilter] = useState("");

    const [filteredComments, setFilteredComments] = useState<Comment[]>([]);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [userId, setUserId] = useState("");
    const [userName, setUserName] = useState("");
    const [isLoading, setIsLoading] = useState(true);

    const navigate = useNavigate();
    const app = getApp();
    const db = getFirestore(app);
    const auth = getAuth();
    const queryParameters = new URLSearchParams(window.location.search);
    const postId = queryParameters.get("id");

    useEffect(() => {
        if (postId) {
            fetchPost();
            fetchComments();
        }
    }, [postId]);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, (user) => {
            if (user) {
                setUserId(user.uid);
                setIsLoggedIn(true);
                setUserName(user.displayName || "Unknown User");
            } else {
                setIsLoggedIn(false);
            }
            setIsLoading(false)
        });

        return () => unsubscribe();
    }, []);


    useEffect(() => {
        setFilteredComments(comments.filter(comment =>
            comment.authorName.toLowerCase().includes(filter.trim().toLowerCase()) &&
            comment.comment.toLowerCase().includes(contentFilter.trim().toLowerCase())
        ));
    }, [contentFilter, filter, comments]);

    const fetchPost = async () => {
        if (postId) {
            const docRef = doc(db, "posts", postId);
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                setPostTitle(docSnap.data().title);
                setPostContent(docSnap.data().content);
            } else {
                console.log("No such document!");
            }
        }
        setIsLoading(false);
    };

    const fetchComments = async () => {
        if (postId) {
            const q = query(collection(db, "comments"), where("postId", "==", postId));
            const querySnapshot = await getDocs(q);
            const fetchedComments: Comment[] = [];
            querySnapshot.forEach((doc) => {
                fetchedComments.push({id: doc.id, ...doc.data()} as Comment);
            });
            fetchedComments.sort((a, b) => b.createDate.toMillis() - a.createDate.toMillis());
            setComments(fetchedComments);
        }
    };

    const formatDate = (timestamp: Timestamp) => {
        const date = timestamp.toDate();
        return date.toLocaleString('en-US', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            hour: '2-digit',
            minute: '2-digit'
        });
    };
    const handleSubmitComment = async () => {
        if (newComment && postId && isLoggedIn) {
            await addDoc(collection(db, "comments"), {
                postId: postId,
                authorId: userId,
                comment: newComment,
                authorName: userName,
                createDate: serverTimestamp()
            });
            setNewComment("");
            fetchComments();
        }
    };

    const handleDeleteComment = async (commentId: string) => {
        await deleteDoc(doc(db, 'comments', commentId));
        fetchComments();
    };

    if (isLoading) {
        return (
            <ThemeProvider theme={theme}>
                <Box sx={{
                    minHeight: '100vh',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    background: `
                        radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
                        radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
                    `,
                    backgroundSize: 'cover',
                    backgroundAttachment: 'fixed',
                }}>
                    <CircularProgress/>
                </Box>
            </ThemeProvider>
        );
    }

    return (
        <ThemeProvider theme={theme}>
            <Box sx={{
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                background: `
                    radial-gradient(circle at 10% 20%, rgba(33, 150, 243, 0.05) 0%, rgba(255, 64, 129, 0.05) 90%),
                    radial-gradient(circle at 90% 80%, rgba(0, 176, 255, 0.05) 0%, rgba(156, 39, 176, 0.05) 90%)
                `,
                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',
            }}>
                <Navbar/>
                <Container maxWidth="md" sx={{mt: 4, mb: 4, flexGrow: 1}}>
                    <Card sx={{mb: 4, p: 3}}>
                        <Typography variant="h4" gutterBottom>{postTitle}</Typography>
                        <Typography variant="body1">{postContent}</Typography>
                    </Card>
                    {isLoggedIn && (
                        <Box sx={{mb: 4, mt: 4}}>
                            <Typography variant="h6" gutterBottom>Add a comment</Typography>
                            <TextField
                                fullWidth
                                multiline
                                rows={4}
                                variant="outlined"
                                value={newComment}
                                onChange={(e) => setNewComment(e.target.value)}
                                sx={{mb: 2}}
                            />
                            <Button
                                onClick={handleSubmitComment}
                                variant="contained"
                                color="primary"
                            >
                                Submit Comment
                            </Button>
                        </Box>
                    )}
                    <Box sx={{
                        mb: 3
                    }}>
                        <Typography variant="h6" gutterBottom>Comments</Typography>
                        <Box sx={{
                            mb: 3,
                            display: 'flex',
                            flexDirection: 'row',

                        }}>
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={contentFilter}
                                onChange={(e) => setContentFilter(e.target.value)}
                                placeholder="Search by content"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color='purple'/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: contentFilter && (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setContentFilter('')} edge="end">
                                                <ClearIcon sx={{
                                                    color: 'purple',
                                                }}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    mb: 2,
                                    mr: 2,
                                }}
                            />
                            <TextField
                                fullWidth
                                variant="outlined"
                                value={filter}
                                onChange={(e) => setFilter(e.target.value)}
                                placeholder="Search by author"
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <SearchIcon color='purple'/>
                                        </InputAdornment>
                                    ),
                                    endAdornment: filter && (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => setFilter('')} edge="end">
                                                <ClearIcon sx={{
                                                    color: 'purple',
                                                }}/>
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }}
                                sx={{
                                    mb: 2,
                                }}
                            />
                        </Box>
                    </Box>

                    {filteredComments.map((comment) => (
                        <Card key={comment.id} sx={{mt: 2, mb: 2, p: 2}}>
                            <CardContent>
                                <Typography variant="subtitle1" gutterBottom>
                                    <strong>{comment.authorName} </strong>
                                    <Typography variant="caption" color="text.secondary">
                                        {formatDate(comment.createDate)}
                                    </Typography>
                                </Typography>
                                <Typography variant="body2">{comment.comment}</Typography>
                            </CardContent>
                            {userId === comment.authorId && (
                                <Button
                                    onClick={() => handleDeleteComment(comment.id)}
                                    color="error"
                                    size="small"
                                >
                                    Delete
                                </Button>
                            )}
                        </Card>
                    ))}
                </Container>
                <Footer/>
            </Box>
        </ThemeProvider>
    );
}

export default Context;