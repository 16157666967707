import React from 'react';
import './App.css';
import { useState } from 'react';
import {BrowserRouter as Router, Routes, Route, Navigate} from 'react-router-dom';
import Mainpage from './pages/Mainpage';
import Login from './pages/login-page/Login';
import Signup from './pages/login-page/Signup';
import Blog from './pages/blog-page/Blog';
import ConfirmSignup from './pages/login-page/ConfirmSignup';
import createStore from 'react-auth-kit/createStore';
import AuthProvider from 'react-auth-kit';
import Context from './pages/blog-page/Context';
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import Technology from './pages/technology-page/Techfnology';
import Support from './pages/support-page/Support';
import Content from './pages/content-page/Content';
import Order from './pages/order-page/Order';
import ResetPassword from './pages/login-page/ResetPassword';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyCbC8PIQ7p6VMAJ3G2ycAA0PZdd1O7hN9A",
  authDomain: "codeapeel-431615.firebaseapp.com",
  projectId: "codeapeel-431615",
  storageBucket: "codeapeel-431615.appspot.com",
  messagingSenderId: "1071382473464",
  appId: "1:1071382473464:web:3bd871f9084be613d4a90d",
  measurementId: "G-4PK5WFZSW3"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication and get a reference to the service
const auth = getAuth(app);

const store = createStore({
  authName:'_auth',
  authType:'cookie',
  cookieDomain: window.location.hostname,
  cookieSecure: window.location.protocol === 'https:',
});

const profiles:{id: string, name: string, url: string}[] = [
  /*
  {id: "1", name: "App C Help", url: "/helpc"},
  {id: "2", name: "App H Help", url: "/helph"},
  {id: "3", name: "App M Help", url: "/helpm"},
  {id: "4", name: "Tools", url: "/tools"},
  {id: "5", name: "Academic", url: "/academic"},
  {id: "6", name: "Resources", url: "/resources"},
  {id: "7", name: "Support", url: "/support"},
  {id: "8", name: "Content", url: "/content"},
  {id: "9", name: "How to order", url: "/order"},
  {id: "10", name: "App C", url: "/appc"},
  {id: "11", name: "App H", url: "/apph"},
  {id: "12", name: "App M", url: "/appm"}
  */
]

function App() {
  const [results, setResults] = useState<{id : string; name: string; url: string}[]> ();
  const [selectedProfile, setSelectedProfile] = useState<{ id: string; name: string; url: string}>();
  const [userEmail, setUserEmail] = useState("");

  type changeHandler = React.ChangeEventHandler<HTMLInputElement>;

  const handleChange: changeHandler = (e) => {
    const { target } = e;
    if(!target.value.trim()) return setResults([]);

    const filteredValue = profiles.filter((profile) =>
      profile.name.toLowerCase().includes(target.value.toLowerCase())
    );
    setResults(filteredValue);
  };

  const handleClick = (name: string) => {
    if (!name.trim()) return setResults([]);

    const filteredValue = profiles.filter((profile) =>
      profile.name.toLowerCase().includes(name.toLowerCase())
    );
    setResults(filteredValue);
  }

  return (
    <AuthProvider store={store}>
      <Router>
        <Routes>
          <Route path={`/`} element={<Mainpage 
          results={results} 
          onChange={handleChange}
          onClick={handleClick}
          renderItem={(item) => <p>{item.name}</p>}
          onSelect={(item) => setSelectedProfile(item)}
          value={selectedProfile?.name}
          url={selectedProfile?.url!}
          />}/>
          <Route path={`/login`} element={<Login />} />
          <Route path={`/signup`} element={<Signup onClick={(name:string) => setUserEmail(name)}/>} />
          <Route path={'/confirmSignup'} element={<ConfirmSignup userEmail={userEmail}/>} />
          <Route path={`/blog/:category`} element={<Blog />} />
          <Route path={`/context`} element={<Context />} />
          <Route path={`/technology`} element={<Technology />} />
          <Route path={`/support`} element={<Support />} />
          <Route path={`/content`} element={<Content />} />
          <Route path={`/order`} element={<Order />} />
          <Route path={`/resetpassword`} element={<ResetPassword />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </Router>
    </AuthProvider>
  );
}

export default App;
